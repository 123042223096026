.ant-form-item-label {
  display: none;
}

.bubble-input {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #ddd;
  box-shadow: none;
}

.bubble-input:focus {
  border-bottom-color: #0080ff;
  box-shadow: none;
}

.ant-btn-primary {
  background-color: #0080ff;
  border-color: #0080ff;
  border-radius: 10px;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
  background-color: #0066cc;
  border-color: #0066cc;
}
