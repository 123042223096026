.password-reset-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45vh;
  background-color: #f2f2f2;
}

.password-reset-form {
  background-color: white;
  padding: 40px;
  border-radius: 5px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  position: relative;
  width: 500px;
  max-width: 90%;
}

.password-reset-email-input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 2px solid #c9c9c9;
  border-radius: 5px;
  font-size: 16px;
}

.password-reset-submit-button {
  width: 100%;
  padding: 14px 20px;
  background-color: #3f7f3f;
  color: white;
  margin: 8px 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.password-reset-submit-button:hover {
  background-color: #3e8e41;
}

.password-reset-ornamental-break {
  display: none;
}

.password-reset-text {
  text-align: center;
  font-size: 1.2rem;
  margin-top: 1rem;
  color: #3f3d56;
}

