.email-verification {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    margin-bottom: 200px; /* Adjust this value as needed */
    position: relative; /* If it's not already set */
    top: -100px; /* Adjust this value as needed */
  }
  
  .email-verification h2,
  .email-verification p {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .email-verification button {
    margin-top: 20px;
    font-size: 20px; /* Increase the font size */
    border-radius: 50px;
    padding: 20px 40px; /* Increase the padding */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px; /* you can adjust this as needed */
  }
  