
/* UserHistory.css */

.bubble {
    font-size: 1em;
    color: #000000;
    padding: 20px;
    border-radius: 25px;
    background-color: white;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    text-align: left;
    white-space: pre-wrap;
    transition: all 0.3s ease;
}

.bubble:hover {
    transform: scale(1.02);
}

.title {
    font-size: 1.2em;
    color: #000000;
    padding: 10px;
    border-radius: 15px;
    background: linear-gradient(to right, #000000, #434343);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    margin: 10px 0;
    text-align: center;
    white-space: pre-wrap;
    background: linear-gradient(45deg, #d1fae7 0%, #d1fae7 99%, #d1fae7 100%);
    border: 2px solid #fff;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: background-color 0.5s ease;

    transition: all 0.3s ease;
}

.title:hover {
    color: #000000;
}

.container {
    margin-left: auto;
    margin-right: auto;
    max-width: 80%; /* Adjust this to control the maximum width of the content. */
    padding-left: 15px; /* Adjust this to control the left padding of the content. */
    padding-right: 15px; /* Adjust this to control the right padding of the content. */
}


.no-history {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #444;
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 2px 2px 15px rgba(0,0,0,0.1);
    background: #fff;
}
.centered-container {
    position: relative;
    width: 100%;
    height: 100%;
}


.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 2em;
    color: #434343;
    font-family: 'Arial', sans-serif;
}