.status-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
  }
  
  .status-bubble {
    background-color: white;
    border: 2px solid #3F7B7D;
    border-radius: 50px;
    padding: 10px 20px;
  }
  
  .status-text {
    font-size: 14px;
    color: #000000;
    text-align: center;
  }
  