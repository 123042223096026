.reset-password-form {
    width: 40%;
    background-color: #FFFFFF;
    padding: 30px;
    border-radius: 10px;
  }
  
  .reset-password-form h2 {
    text-align: center;
    font-size: 40px;
    color: #5B5B5B;
  }
  
  .reset-password-form input {
    width: 80%;
    height: 50px;
    border-radius: 10px;
    font-size: 30px;
  }
  
  .reset-password-form button {
    width: 40%;
    height: 50px;
    font-size: 20px;
  }
  
  .reset-password-message {
    text-align: center;
    color: #5B5B5B;
  }
  
  .reset-password-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #F8F8F8;
  }
  