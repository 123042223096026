img {
    float: left; /* float the image to the right */
    width: 100%; /* adjust the width of the image */
    height: auto; /* height should be automatically calculated */
}

.container::after {
    content: "";
    display: block;
    clear: both;
}


.tool-container {
    position: relative;
    margin-top: 150px; /* increase the gap between the tile and the title /
    padding-bottom: 80px; / increase the space between the tile and the image */
}

.tool-image {
    width: 37%;
    height: auto;
    position: absolute;
    top: -150px; /* adjust the distance from the top of the box */
    left: 50%;
    transform: translateX(-50%);
}


