.logo-image {
    width: 200px;
    height: 200px;
  }
  
  @media screen and (max-width: 768px) {
    .logo-image {
      width: 100px;
      height: 100px;
    }
  }
  